
.a-section-title_wrapper {
  padding-bottom: 40px;
  padding-left: 0;
  padding-right: 0;
}

.a-section-title {
  display: flex;
  flex-wrap: nowrap;
  font-size: larger;

  & span {
    font-weight: $fw-semibold;
    text-transform: uppercase;
  }
}

.a-section-title_line {
  flex: 1;
  margin-top: 12px;
  margin-left: 10px;
}

.a-section_description {
  padding-top: 15px;
  font-weight: $fw-regular
}

.a-section_description-bold {
  font-weight: bold;
}
