.a-block-information_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 73px;
  background-color: green;
  color: white;
  padding: 20px 20px 20px 20px;
}

.a-block-information_wrapper {
  padding-bottom: 25px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.a-block-information_red {
  background-color: #b62828;
}

.a-block-information_green {
  background-color: #32be3f;
}
