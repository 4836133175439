.m-date-input__wrapper {
  display: flex;
  width: 100%;
}

.m-date-input__icon {
  position: absolute;
  right: 0;

  &.valid {
    color: $c-valid;
    top: 8px;
    right: 47px;
    font-size: 10px;

  }

  &.error {
    opacity: 0.4;
    color: grey;
    top: 8px;
    right: 47px;
    font-size: 10px;
  }
}

