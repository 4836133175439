.a-personal-greeting__align-center {
  display: flex;
  justify-content: center;

  &.salutation {
    padding: 53px 0 20px 0;
    font-weight: 500;
  }

  &.success-message {
    padding-bottom: 50px;
    font-size: large;
    font-weight: 500;
  }
}
