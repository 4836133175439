//== Fonts
//

//** font families
$ff-helvetica: 'Helvetica Neue', helvetica, arial, sans-serif;

//** font-weights
$fw-small: 		    300;
$fw-regular: 		400;
$fw-semibold: 		500;
$fw-bold: 			600;

//** font-sizes
$fs-atomic: 		em(10);
$fs-tiny: 			em(12);
$fs-small: 			em(14);
$fs-normal: 		em(15);
$fs-mobile: 		em(16);
$fs-middle: 		em(18);
$fs-stable:			em(23);
$fs-big: 			em(24);

//** line-height
$lh-small: 			.9;
$lh-flat: 			1.1;
$lh-small: 			1.2;
$lh-normal: 		1.3;
$lh-big:			1.6;
$lh-huge:			1.9;



//== Colors

$c-bg-grey:		    #f8f8f8 !default;
$c-error: 			#e85454 !default;
$c-valid: 			#71b7e2 !default;
$c-lila:			#8c21eb !default;
$c-dark-blue:		#002A5F !default;

// modification
$c-green-light:     #eaf4ff !default;
$c-green-dark:      #2559a2 !default;
$c-organge-dark:	#4196ff !default;
// fonts
$fc-black:          #383838 !default;
$fc-normal:         $fc-black !default;
$fc-soft:           #555 !default;
$fc-light:          #7C7C7C !default;
$fc-error:          #B71C3E !default;


$c-ciorange:		#4196ff !default;

//== Icons
$icon-size-tiny: 16px;
$icon-size-small: 18px;
$icon-size-normal: 24px;
$icon-size-big: 32px;
$icon-size-huge: 48px;

//==Inputs
$standard-input-height: 38px;
