.m-input__input {
  height: $standard-input-height;
  font-weight: $fw-regular;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  background-color: $c-bg-grey;
  box-shadow: 0 0 10px rgba($c-ciorange, .10) inset;

  &.error {
    border: 1px solid $c-error;
  }

  &.valid {
    border: 1px solid $c-valid;
  }
}

.m-input__icon {
  position: absolute;
  right: 0;

  &.valid {
    color: $c-valid;
    top: 3px;
    right: 8px;
    font-size: 20px;
  }

  &.error {
    opacity: 0.4;
    color: grey;
    top: 3px;
    right: 8px;
    font-size: 20px;
  }
}
