.a-completion-button__input {
  height: 50px;
  border-radius: 25px;
  padding: 25px 0 25px 0;
  background-color: $c-dark-blue;
  color: white;
  font-size: large;
  line-height: 0;
  width: 100%;
  border: transparent;

  &:focus {
    outline: none;
  }
}

.a-completion-button__wrapper {
  align-items: center;
}

.a-completion-button__line {
  flex: 1;
  margin-top: 13px;
  margin-right: 16px;
  display: none;
}

.a-completion-button__form-oppacity {
  opacity: 0.5;
}

@media (min-width: 768px) {
  .a-completion-button__line {
    display: block;
  }

  .a-completion-button__input {
    width: auto;
    padding: 25px 50px 25px 50px;
  }
}
