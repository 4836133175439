
.m-nationality-select__inputfield {
  height: $standard-input-height;
  min-height: $standard-input-height;

  > div {
    background-color: #f8f8f8;
    border: 1px solid #bfbfbf;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba($c-ciorange, .10) inset;
    z-index: 3;
  }

  &.error {
    > div {
      border: 1px solid $c-error;
      > div {
        > div.css-1uccc91-singleValue {
          color: grey;
          opacity: 0.5;
        }
      }
    }
  }

  &.valid {
    > div {
      border: 1px solid $c-valid;
    }
  }
}

.m-nationality-select__icon {
  position: absolute;
  right: 0;

  &.valid {
    color: $c-valid;
    top: 8px;
    right: 47px;
    z-index: 3;
    font-size: 10px;

  }

  &.error {
    opacity: 0.4;
    color: grey;
    top: 8px;
    right: 47px;
    z-index: 3;
    font-size: 10px;

  }
}

