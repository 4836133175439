
%m-immutable-data-item_padding {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 13px;
}

.m-immutable-data-item_label {
  @extend %m-immutable-data-item_padding;
  font-style: italic;
  font-weight: $fw-small;
}

.m-immutable-data-item {
  @extend %m-immutable-data-item_padding;
  font-weight: $fw-regular;
}
