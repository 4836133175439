@keyframes ripple {

  0% {
    box-shadow: 0 0 0 0px $md-radio-checked-color;
  }

  100% {
    box-shadow: 0 0 7px $md-radio-ripple-size $md-radio-checked-color;
  }
}

.m-checkbox_wrapper {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 15px;
  cursor: pointer;
}

.m-checkbox__label {
  height: 100%;
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  min-height: 23px;
  margin-left: 37px;
  & span {
    font-weight: $fw-small;
  }

  &.a-label {
    font-style: normal;
  }
}

.m-checkbox__label::before {
  box-sizing: border-box;
  background-color: #f8f8f8;
  content: '';
  opacity: 1;
  border: 1px solid #bfbfbf;
  position: absolute;
  border-radius: 2px;
  display: inline-block;
  margin-left: -57px;
  margin-top: -13px;
  width: 26px;
  height: 26px;
  top: 15%;
  left: 20px;
  transition: border-color .15s ease-in-out;
  vertical-align: top;
}

.m-checkbox__label::before:checked {
  animation: ripple 0.3s linear forwards;
  border: $c-valid;
}

.m-checkbox__label::after {

  position: absolute;
  content: '';
  background-color: $c-valid;
  margin-left: -52px;
  margin-top: -9px;
  top: 16%;
  left: 20px;
  width: 15px;
  height: 15px;
  opacity: 0;
  transition: opacity, .3s ease-in-out;
}

.m-checkbox__input {
  cursor: pointer;
  display: none;

  &:checked + .m-checkbox__label::after {
    transition: opacity, .3s ease-in-out;
    opacity: 1;
  }

  &:checked + .m-checkbox__label::before {
    animation: ripple 0.3s linear forwards;
    border: $c-valid;
    border-radius: 2px;
  }
}

.m-checkbox__link {
  color: $c-valid;
}

@media (min-width: 768px) {
  .m-checkbox__label::before {
    margin-top: -4px;
  }
  .m-checkbox__label::after {
    margin-top: 0;
  }
}
