
.ant-calendar-picker-input.ant-input {
  height: $standard-input-height;
  font-weight: $fw-regular;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  background-color: $c-bg-grey;
  color: $fc-black;
  box-shadow: 0 0 10px rgba($c-ciorange, .10) inset;

  .error & {
    border: 1px solid $c-error;
  }

  .valid & {
    border: 1px solid $c-valid;
  }
}
