
.a-error-message__wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  p {
    color: $fc-error;

    &::before {
      display: inline;
      content: "⚠ ";
    }
  }
}
