@use "sass:math";
$md-radio-checked-color: $c-valid;
$md-radio-border-color: #bfbfbf;
$md-radio-background-color: $c-bg-grey;
$md-radio-size: 25px;
$md-radio-checked-size: 15px;
$md-radio-ripple-size: 1px;

@keyframes ripple {

  0% {
    box-shadow: 0 0 0 0px $md-radio-checked-color;
  }

  100% {
    box-shadow: 0 0 7px $md-radio-ripple-size $md-radio-checked-color;
  }
}

.m-radio-button {
  margin-bottom: 25px;
  padding-left: 0;

  input[type="radio"] {
    display: none;

    &:checked + label:before {
      animation: ripple 0.3s linear forwards;
      border: $md-radio-checked-color;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  .m-radio-button_label {
    display: inline-block;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    font-weight: $fw-small;

    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 1px solid $md-radio-border-color;
      background-color: $md-radio-background-color;
    }

    &:after {
      top: math.div($md-radio-size, 2) - math.div($md-radio-size, 2);
      left: math.div($md-radio-size, 2) - math.div($md-radio-size, 2);
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }

    &.error:before {
      border: 1px solid $c-error;
    }

    & .description {
      font-size: smaller;
    }

    & .arrange-label {
      margin-top: 2px;
    }

    & .arrange-description {
      margin-top: -6px;
    }
  }
}
