
.a-data-title__wrapper {
  font-weight: $fw-semibold;
  font-style: italic;
  color: #383838;
  text-align: left;
  padding-left: 0;
  margin-bottom: 17px;
}

.a-data-title__tenant-name {
  text-decoration: none;
  font-weight: $fw-regular
}

.a-data-title__title {
  text-decoration: underline;
  font-weight: $fw-regular
}
