.a-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
}
.a-modal__inner {
  position: absolute;
  left: 15%;
  right: 15%;
  top: 30%;
  margin: auto;
  background: white;
  min-height: 200px;
  max-width: 720px;
  padding: 20px 20px 20px 20px;
  font-size: 20px;
  font-style: italic;
  box-shadow: 0 0 35px rgba(65, 150, 255, 0.1) inset;
  border-radius: 7px;
  display: flex;
  align-items: center;
}

.a-modal__content {
  width: 100%;
}

.a-modal__not-transparent {
  background-color: lightgray;
}

.a-modal__transparent {
  background-color: rgba(0,0,0, 0.5);
}

.a-modal__center-text {
  text-align: center;
}
